import React, {useEffect, useState} from "react";
import Table from "react-bootstrap/Table";
import Dropdown from "react-bootstrap/Dropdown";

const View_list = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedYear, setSelectedYear] = useState("2024");
  const [donors_2023, setDonors_2023] = useState([]);
  const [filteredData2, setFilteredData2] = useState([]);

  useEffect(() => {
    fetch(
      `https://wms-sf6o.onrender.com/donor_list?year=${selectedYear}`
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data)
        setData(data.donors)
        setDonors_2023(data.donors_2023)
        const filteredData2 = data.donors_2023.filter((item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        const filteredData = data.donors.filter((item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredData(filteredData);
        setFilteredData2(filteredData2);
    })
      .catch((err) => console.log(err));
  }, [selectedYear]);

  // Function to format date as "dd-mm-yyyy"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filteredData);
    const filteredData2 = donors_2023.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData2(filteredData2);
  }, [searchQuery]);

  return (
    <>
      <div
        className="container"
        style={{
          marginTop: "50px",
          marginBottom: "50px",
          fontFamily: "Poppins",
        }}>
        <div
          className="main"
          style={{
            display: "flex",
            backgroundColor: "#fff455",
            justifyContent: "space-between",
            borderRadius: "20px 20px 0px 0px",
          }}>
          {/* Dropdown for selecting year */}
          <div className="dropdown mx-2 my-2">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                style={{
                  backgroundColor: "#fff",
                  color: "#a44600",
                  border: "none",
                  fontWeight:"bold",
                  borderRadius: "40px",
                }}
                className="animate__animated animate__backInDown">
                {selectedYear}
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  width: "10%",
                  textAlign: "center",
                  background:"#a44600",
                }}>
                <Dropdown.Item onClick={() => setSelectedYear("2024")} style={{color: "#fff455",fontWeight:"bold"}}>
                  2024
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setSelectedYear("2023")} style={{color: "#fff455",fontWeight:"bold"}}>
                  2023
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {/* Search bar */}
          <div className="searchbar mx-2 my-2">
            <input
              type="text"
              placeholder="Search by name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                border: "none",
                textAlign: "center",
                borderRadius: "40px",
                color:"black",                
              }}
              className="animate__animated animate__backInDown"
            />
          </div>
        </div>

        {/* Table for displaying data */}
        <Table
          striped
          bordered
          hover
          style={{
            textAlign: "center",
            overflow: "hidden",
          }}>
          <thead >
            <tr className="animate__animated animate__zoomIn">
              <th style={{color:"#a44600"}}>Name</th>
              <th style={{color:"#a44600"}}>Amount</th>
              <th style={{color:"#a44600"}}>Place</th>
              <th style={{color:"#a44600"}}>Date</th>
            </tr>
          </thead>
          <tbody>
            {/* Map through filtered data to render rows */}
            {filteredData.map((d, i) => (
              <tr key={i}>
                {/* Render columns for each data item */}
                <td className="animate__animated animate__fadeInDownBig">
                  {d.name}
                </td>
                <td className="animate__animated animate__fadeInUpBig">
                  {d.donors_details[0].amount}₹
                </td>
                <td className="animate__animated animate__fadeInDownBig">
                  {d.address}
                </td>
                {/* Format date column */}
                <td className="animate__animated animate__fadeInUpBig">
                  {formatDate(d.donors_details[0].date)}
                </td>
              </tr>
            ))}
            {selectedYear == "2023" && filteredData2.map((d, i) => (
              <tr key={i}>
                {/* Render columns for each data item */}
                <td className="animate__animated animate__fadeInDownBig">
                  {d.name}
                </td>
                <td className="animate__animated animate__fadeInUpBig">
                  {d.amount}₹
                </td>
                <td className="animate__animated animate__fadeInDownBig">
                  {d.place}
                </td>
                {/* Format date column */}
                <td className="animate__animated animate__fadeInUpBig">
                  {formatDate(d.date)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default View_list;
