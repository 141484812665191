import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Table from "react-bootstrap/Table";
import "./Book_Place_Status.css";

function Book_Place_Status() {
  const [placeReserve, setPlaceReserve] = useState([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    // localStorage.setItem("admin", "true");
    if (localStorage.getItem("admin") !== "true") {
      navigate("/");
    }
  }, []);

  // Function to format date as "dd-mm-yyyy"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Fetch place_reserve details
  useEffect(() => {
    axios
      .get("https://wms-sf6o.onrender.com/place-reservation")
      .then((res) => {
        setPlaceReserve(res.data);
        console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  // Function to delete a place_reserve
  const handleDelete = (id, res_id) => {
    const updatedDonors = placeReserve.filter((_, i) => i.id !== id);
    axios
      .post(`https://wms-sf6o.onrender.com/place-reservation/update`, {
        status: "Rejected",
        id: res_id
      })
      .then((res) => {
        console.log(res.data);
        if (res.data === "Updated") {
          setPlaceReserve(updatedDonors);
        }
      });
  };

  // Function to add place_reserve data to another page
  const handleAddToAnotherPage = (id, res_id) => {
    const updatedDonors = placeReserve.filter((donor) => donor.id !== id);
    axios
      .post(`https://wms-sf6o.onrender.com/place-reservation/update`, {
        status: "Approved",
        id: res_id  
      })
      .then((res) => {
        console.log(res.data);
        if (res.data === "Updated") {
          setPlaceReserve(updatedDonors);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      className="container mt-5"
      style={{
        textAlign: "center",
      }}
    >
      <div className="content" style={{
            backgroundColor:"#fff455",borderRadius: "20px 20px 0px 0px"}}>
        <h3 style={{ backgroundColor: "#fff455",color:"#a44600", borderRadius: "20px 20px 0px 0px" }}>PLACE RESERVATION STATUS</h3>
        <Table striped bordered hover>
          <thead className="heading">
            <tr>
              <th>Name</th>
              <th>Contact NO.</th>
              <th>Candidates</th>
              <th>Date</th>
              <th colSpan={2}>Action</th>
            </tr>
          </thead>
          <tbody>
            {placeReserve?.map((place_reserve, index) => (
              <tr key={index}>
                <td>{place_reserve.name}</td>
                <td>{place_reserve.mobno}</td>
                <td>{place_reserve.reserve_place[0].no_of_candidates}</td>
                <td>{formatDate(place_reserve.reserve_place[0].date)}</td>
                <td>
                  <input
                    type="button"
                    style={{ background: "none", color: "red" }}
                    value={"❌"}
                    onClick={() => handleDelete(place_reserve.id, place_reserve.reserve_place[0].id)}
                  />
                </td>
                <td>
                  <input
                    type="button"
                    style={{ background: "none", color: "green" }}
                    value={"➕"}
                    onClick={() => handleAddToAnotherPage(place_reserve.id, place_reserve.reserve_place[0].id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default Book_Place_Status;
