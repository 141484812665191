import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import "./Articles.css"; // Assuming you have some styles here

function GroupExample() {
  const [showFullText, setShowFullText] = useState({
    item1: false,
    item2: false,
    item3: false,
  });

  const [showModal, setShowModal] = useState(false);

  const toggleText = (item) => {
    setShowFullText((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
    }));
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <div className="slide-component">
        <h3
          style={{ boxShadow: "5px 5px 10px #F8EDED, -5px -5px 10px #F8EDED" }}
        >
          ARTICLES
        </h3>
        <Carousel interval={3000} controls={false} indicators={false}>
          {" "}
          {/* Removed controls */}
          <Carousel.Item>
            <Card>
              <Card.Img variant="top" src="../assets/images/idea.png" />
              <Card.Body>
                <Card.Title>Idea</Card.Title>
                <Card.Text>
                  {showFullText.item1
                    ? "The idea to start biharipoojapath.com was born when the founders visited their village committee to discuss organizing a worship event. The committee chairman requested that they collect donations from local shopkeepers and interested devotees. During this process, the founders noticed that approaching shopkeepers for donations while they were serving customers was disruptive and time-consuming. It often delayed customer service, as committee members went from shop to shop asking for donations, interrupting transactions. Realizing this was inefficient for both the committee and shopkeepers, the founders decided to digitize the process, aiming to solve this real-world problem and align with the vision of Digital India."
                    : "The idea to start biharipoojapath.com was born when the founders visited their village committee to discuss..."}
                </Card.Text>
              </Card.Body>
              <Card.Footer onClick={() => toggleText("item1")}>
                <big className="text-muted">
                  {showFullText.item1 ? "Read-Less" : "Read-More"}
                </big>
                <FontAwesomeIcon
                  icon={faArrowRightLong}
                  style={{ marginLeft: "10px" }}
                />
              </Card.Footer>
            </Card>
          </Carousel.Item>
          <Carousel.Item>
            <Card>
              <Card.Img variant="top" src="../assets/images/main-purpose.jpg" />
              <Card.Body>
                <Card.Title>Vision</Card.Title>
                <Card.Text>
                  {showFullText.item2
                    ? "Our website is dedicated to hosting Bihari worship committees online, offering a platform to access information about donations and the latest activities of various committees. We cover a range of cultural and religious events, including Durga Pooja, Chhath Pooja, Ram Navami, Saai Pooja, and more."
                    : "Our website is dedicated to hosting Bihari worship committees online, offering a platform to access info..."}
                </Card.Text>
              </Card.Body>
              <Card.Footer onClick={() => toggleText("item2")}>
                <big className="text-muted">
                  {showFullText.item2 ? "Read-Less" : "Read-More"}
                </big>
                <FontAwesomeIcon
                  icon={faArrowRightLong}
                  style={{ marginLeft: "10px" }}
                />
              </Card.Footer>
            </Card>
          </Carousel.Item>
          <Carousel.Item>
            <Card>
              <Card.Img variant="top" src="../assets/images/founder.png" />
              <Card.Body>
                <Card.Title>Founder</Card.Title>
                <Card.Text>
                  {showFullText.item3 ? (
                    <>
                      <div>
                        Rajan Patel, the visionary founder from Rohtas district
                        in Bihar, completed his schooling at Patna University.
                        He pursued his graduation in Computer Applications from
                        Ranchi University. Driven by his passion for the field,
                        he decided to further his education and cracked the
                        MAH-CET entrance exam, securing admission for his
                        master's degree at Savitribai Phule Pune University
                        (SPPU) in Maharashtra. Rajan has always dedicated
                        himself to his studies, striving to achieve excellence
                        in every endeavor.Currently working as a S/W Engineer in
                        gurgaon.
                        <span>
                          <a
                            href="https://shudhgarib.github.io/web/"
                            target="_blank"
                            style={{ color: "blue", textDecoration: "none" }}
                          >
                            His-Portfolio
                          </a>
                        </span>
                      </div>
                      <div
                        style={{
                          marginTop: "15px",
                          textAlign: "center",
                          border: "none",
                        }}
                      >
                        <img
                          src="../assets/images/map-chart.png"
                          style={{
                            width: "100%",
                            maxWidth: "300px",
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            cursor: "pointer",
                          }}
                          alt="Map of India"
                          onClick={handleShowModal}
                        />
                      </div>
                    </>
                  ) : (
                    "Rajan Patel, the visionary founder from Rohtas district in Bihar, completed his schooling at Patna University..."
                  )}
                </Card.Text>
              </Card.Body>
              <Card.Footer onClick={() => toggleText("item3")}>
                <big className="text-muted">
                  {showFullText.item3 ? "Read-Less" : "Read-More"}
                </big>
                <FontAwesomeIcon
                  icon={faArrowRightLong}
                  style={{ marginLeft: "10px" }}
                />
              </Card.Footer>
            </Card>
          </Carousel.Item>
        </Carousel>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Body>
          <img
            src="../assets/images/map.png"
            style={{ width: "100%", height: "auto" }}
            alt="Map of India"
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleCloseModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GroupExample;
