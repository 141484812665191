import React from "react";
import "./YouTubeVideo.css";

const YouTubeVideo = ({ videoId }) => {
  return (
    <>
    <div className="content">    
    <div className="container youtube-video-container">
      <iframe
        src={`https://www.youtube.com/embed/${videoId}?controls=0&rel=0&showinfo=0&modestbranding=1`}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        title="YouTube video player"
        className="youtube-video-iframe"
        />
    </div>
    </div>
        </>
  );
};

export default YouTubeVideo;
