import React from "react";
import Carousel_page from "./Carousel_page";
import WelcomeText from "./Subpage/WelcomeText";

import Articles from "./Articles";
import BihariPoojaPath from "./why_bihari_pooja_path";
import YouTubeVideo from "./YouTubeVideo";
// import Options from "../menu/Options";
function Home() {
  return (
    <>
      {/* <Options /> */}
      <Carousel_page />
      <WelcomeText />
      <BihariPoojaPath/>
      <Articles/>
      <YouTubeVideo videoId="B1xis4FFr7o" />
    </>
  );
}

export default Home;
