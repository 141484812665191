import React from "react";
import "./About.module.css";

const About = () => {
  return (
    <>
      <div className="container" style={{color: "#fff"}}>
        <div className="page" style={{marginTop: "20px"}}>
          <h4 className="animate__animated animate__slideInLeft">
            संझौली छठ पूजा समिति !
          </h4>
          <hr />
          <p align="center">
            <span>
              श्री श्री छठ पुजा समिति (संझौली) एकता नवयुवक संघ के द्वारा
              संचालित।
            </span>
            <br />
            <br />
            <span>उद्देश्य :</span> छठ पूजा की गरिमा बनाए रखते हुए भव्य तरीके से
            भगवान सूर्य की प्रतिमा स्थापित एवम् शिव सरोवर का सौंदर्यकरण करना।
            <br />
            <br />
            समिति की बुनियादी ढांचा एकता नवयुवक संघ की समिति की{" "}
            <span> स्थापना </span>
            हमारे गार्जियन <span>( धनंजय सिंह + सतेंद्र प्रजापति )</span>
            द्वारा आज से लगभग <span>20 वर्ष पूर्व </span> की गई थीं।
            <br />
            <br />
            समिति की प्रगतिशीलता हमारे अभिभावक ने बहुत सराहनीय कार्य करते हुए
            गरीमा को बरकरार रखते हुए समिति की बागडोर संभालते हुए ।
            <span> 2017</span> तक लाए और आगे की जिमेवारी को संभालने के लिए नव
            निर्वाचित अध्यक्ष <span>सत्यम पटेल</span> को सर्वसहमति से घोषित किया
            गया। और इन्हीं की अध्यक्षता में समिति अभी तक कार्यरत हैं। और सत्यम
            पटेल से पहले भी हमारे अभिभावक कुछ अध्यक्ष के पद पर थे जिनमें{" "}
            <span>अजीत पटेल, मंटू पटेल, धर्म पटेल , हरेंद्र जी</span> शामिल थे।
            इन सभी के अनुभव और सहयोग से समिति आगे की ओर अग्रसर हों रही हैं।
            <br />
            <br />
            वर्तमान समिति सहयोगी सभी सदस्य हैं,जिसमें मुख्य रूप से{" "}
            <span>
              सोनू पटेल, रवि पटेल, नीरज पटेल, रितेश पटेल,राजन पटेल, अमन पटेल{" "}
            </span>{" "}
            एवम् बाकि सभी अभिभावक तथा युवा सहयोगी।
            <br />
            <br />
            <span>पूजा अर्चना</span> वंदन हर वर्ष छठ पूजा के दिन एकता नवयुवक संघ
            पूरे हर्ष उल्लाश के साथ इस पुजा को करते हैं जिसमें{" "}
            <span>शिव जगत मिश्रा जी </span> के द्वारा हम मंत्र मुग्ध होकर भगवान
            सूर्य की ओर ध्यान केंद्रित करते हैं और अपनी मनोकामनाएं उनके समक्ष
            रखते हैं।
            <br />
            <br />
            <span>समिति का लक्ष्य:</span> कदाचार मुक्त होकर पूजा अर्चना वंदन
            करते रहना और बेहतर से बेहतरीन प्रदर्शन करते रहना जिनमें सभी की
            सहभागिता सुनिश्चित होती हैं।
            <br />
            <br />
            छठ के दिन की चहल पहल शिव सरोवर के चारों तरफ का सौंदैर्यकरण , सभी
            देवताओं की मन्दिर की सजावट, एवम्
            <span> मध्य में स्थित सूर्य भगवान की प्रतिमा</span> हम सभी की मन
            मोहते हैं, और चारो तरफ बच्चे, बुजुर्ग, वर्ती, माताएं बहनों, भाई
            बंधुओं, अभिभावकों की मौजूदगी दर्ज प्राप्त होती हैं।
            <br />
            <br />
            <span>प्रतिमा विसर्जन: </span> जुलूस शोभा यात्रा जुलूस का निर्गमान
            भी काफ़ी धूम धाम से अनुशासनपूर्वक भव्य तरीके से होता हैं। जिसमें
            हमारे अभिभावक एवम प्रशासन के तरफ से भरपूर सहयोग मिलता हैं।
            <br />
            <br />
            <span>जुलूस दायरा:</span> शिव सरोवर से लेकर उदय पुर मोड़ से होकर
            बाड़ी पुल तक।
            <br />
          </p>
        </div>
        <div className="page">
          <h4 className="animate__animated animate__slideInLeft"> छठ पूजा</h4>
          <hr />
          <p>
            छठ पर्व कार्तिक शुक्ल पक्ष के षष्ठी को मनाया जाने वाला एक हिन्दू
            पर्व है। सूर्योपासना का यह अनुपम लोकपर्व मुख्य रूप से
            <span>
              {" "}
              बिहार, झारखण्ड, पश्चिम बंगाल, पूर्वी उत्तर प्रदेश और नेपाल
            </span>{" "}
            के तराई क्षेत्रों में मनाया जाता है। कहा जाता है यह पर्व मैथिली,मगध
            और भोजपुरी लोगो का सबसे बड़ा पर्व है ये उनकी संस्कृति है। <br />
            छठ पर्व बिहार मे बड़े धुम धाम से मनाया जाता है। <span></span> ये एक
            मात्र ही बिहार या पूरे भारत का ऐसा पर्व है जो वैदिक काल से चला आ रहा
            है और ये बिहार कि संस्कृति बन चुका हैं। यहा पर्व बिहार कि वैदिक आर्य
            संस्कृति कि एक छोटी सी झलक दिखाता हैं।{" "}
            <span>
              ये पर्व मुख्यः रुप से ॠषियो द्वारा लिखी गई ऋग्वेद मे सूर्य पूजन,
              उषा पूजन और आर्य परंपरा के अनुसार बिहार मे यह पर्व मनाया जाता हैं।
            </span>
            <br />
            <br />
            बिहार मे हिन्दुओं द्वारा मनाये जाने वाले इस पर्व को इस्लाम सहित अन्य
            धर्मावलम्बी भी मनाते देखे जाते हैं। धीरे-धीरे यह त्योहार प्रवासी
            भारतीयों के साथ-साथ विश्वभर में प्रचलित हो गया है। छठ पूजा सूर्य,
            प्रकृति,जल, वायु और उनकी बहन छठी म‌इया को समर्पित है। मिथिला में छठ
            के दौरान मैथिल महिलाएं, मिथिला की शुद्ध पारंपरिक संस्कृति को दर्शाने
            के लिए बिना सिलाई के शुद्ध सूती धोती पहनती हैं।
            <br />
            <br />
            त्यौहार के अनुष्ठान कठोर हैं और चार दिनों की अवधि में मनाए जाते हैं।
            इनमें पवित्र स्नान, उपवास और पीने के पानी (वृत्ता) से दूर रहना, लंबे
            समय तक पानी में खड़ा होना, और प्रसाद (प्रार्थना प्रसाद) और अर्घ्य
            देना शामिल है। परवातिन नामक मुख्य उपासक (संस्कृत पार्व से, जिसका
            मतलब 'अवसर' या 'त्यौहार') आमतौर पर महिलाएं होती हैं। हालांकि, बड़ी
            संख्या में पुरुष इस उत्सव का भी पालन करते हैं क्योंकि छठ
            लिंग-विशिष्ट त्यौहार नहीं है। छठ महापर्व के व्रत को स्त्री - पुरुष -
            बुढ़े - जवान सभी लोग करते हैं।
            <br />
          </p>
        </div>
        <div className="page">
          <h4 className="animate__animated animate__slideInLeft">
            {" "}
            लोक आस्था का पर्व
          </h4>
          <hr />
          <p>
            भारत में छठ <span>सूर्योपासना</span> के लिए प्रसिद्ध पर्व है। मूलत:
            सूर्य षष्ठी व्रत होने के कारण इसे छठ कहा गया है। यह पर्व वर्ष में{" "}
            <span> दो </span> बार मनाया जाता है। पहली बार <span>चैत्र </span>{" "}
            में और दूसरी बार
            <span> कार्तिक </span>
            में। चैत्र शुक्ल पक्ष षष्ठी पर मनाये जाने वाले छठ पर्व को चैती छठ व
            कार्तिक शुक्ल पक्ष षष्ठी पर मनाये जाने वाले पर्व को कार्तिकी छठ कहा
            जाता है।{" "}
            <span>
              पारिवारिक सुख-समृद्धी तथा मनोवांछित फल प्राप्ति के लिए यह पर्व
              मनाया जाता है।
            </span>{" "}
            स्त्री और पुरुष समान रूप से इस पर्व को मनाते हैं। छठ व्रत के सम्बन्ध
            में अनेक कथाएँ प्रचलित हैं; उनमें से एक कथा के अनुसार{" "}
            <span>
              "जब पांडव अपना सारा राजपाट जुए में हार गये, तब श्री कृष्ण द्वारा
              बताये जाने पर द्रौपदी ने छठ व्रत रखा। तब उनकी मनोकामनाएँ पूरी हुईं
              तथा पांडवों को उनका राजपाट वापस मिला।"
            </span>{" "}
            लोक परम्परा के अनुसार सूर्यदेव और छठी मइया का सम्बन्ध भाई-बहन का है।
            <br />
            <br />
            छठ पर्व को <span>वैज्ञानिक दृष्टिकोण</span> से देखा जाए तो षष्ठी
            तिथि (छठ) को एक विशेष खगोलीय परिवर्तन होता है, इस समय सूर्य की
            पराबैगनी किरणें पृथ्वी की सतह पर सामान्य से अधिक मात्रा में एकत्र हो
            जाती हैं इस कारण इसके सम्भावित कुप्रभावों से मानव की यथासम्भव रक्षा
            करने का सामर्थ्य प्राप्त होता है। पर्व पालन से सूर्य (तारा) प्रकाश
            (पराबैगनी किरण) के हानिकारक प्रभाव से जीवों की रक्षा सम्भव है।
            पृथ्वी के जीवों को इससे बहुत लाभ मिलता है।
            <br />
            <br />
            सूर्य के प्रकाश के साथ उसकी पराबैगनी किरण भी चंद्रमा और पृथ्वी पर
            आती हैं। सूर्य का प्रकाश जब पृथ्वी पर पहुँचता है, तो पहले वायुमंडल
            मिलता है। वायुमंडल में प्रवेश करने पर उसे आयन मंडल मिलता है।
            पराबैगनी किरणों का उपयोग कर वायुमंडल अपने ऑक्सीजन तत्त्व को
            संश्लेषित कर उसे उसके एलोट्रोप ओजोन में बदल देता है। इस क्रिया
            द्वारा सूर्य की पराबैगनी किरणों का अधिकांश भाग पृथ्वी के वायुमंडल
            में ही अवशोषित हो जाता है। पृथ्वी की सतह पर केवल उसका नगण्य भाग ही
            पहुँच पाता है। सामान्य अवस्था में पृथ्वी की सतह पर पहुँचने वाली
            पराबैगनी किरण की मात्रा मनुष्यों या जीवों के सहन करने की सीमा में
            होती है। अत: सामान्य अवस्था में मनुष्यों पर उसका कोई विशेष हानिकारक
            प्रभाव नहीं पड़ता, बल्कि उस धूप द्वारा हानिकारक कीटाणु मर जाते हैं,
            जिससे <span>मनुष्य या जीवन को लाभ होता है।</span>
            <br />
            <br />
            छठ जैसी खगोलीय स्थिति (चंद्रमा और पृथ्वी के भ्रमण तलों की सम रेखा के
            दोनों छोरों पर) सूर्य की पराबैगनी किरणें कुछ चंद्र सतह से परावर्तित
            तथा कुछ गोलीय अपवर्तित होती हुई, पृथ्वी पर पुन: सामान्य से अधिक
            मात्रा में पहुँच जाती हैं। वायुमंडल के स्तरों से आवर्तित होती हुई,
            सूर्यास्त तथा सूर्योदय को यह और भी सघन हो जाती है। ज्योतिषीय गणना के
            अनुसार यह घटना कार्तिक तथा चैत्र मास की अमावस्या के छ: दिन उपरान्त
            आती है। ज्योतिषीय गणना पर आधारित होने के कारण इसका नाम और कुछ नहीं,
            बल्कि छठ पर्व ही रखा गया है।
            <br />
          </p>
        </div>
        <div className="page">
          <h4 className="animate__animated animate__slideInLeft"> व्रत </h4>
          <hr />
          <p>
            <span> छठ पूजा चार दिवसीय उत्सव है।</span> इसकी शुरुआत कार्तिक शुक्ल
            चतुर्थी को तथा समाप्ति कार्तिक शुक्ल सप्तमी को होती है। इस दौरान
            व्रतधारी लगातार 36 घंटे का व्रत रखते हैं। इस दौरान वे
            <span> पानी भी ग्रहण नहीं करते।</span>
            <pre>
              <span>
                नहाय खाय <br /> खरना और लोहंडा <br /> संध्या अर्घ्य उषा अर्घ्य{" "}
              </span>
            </pre>
          </p>
        </div>
        <div className="page">
          <h4 className="animate__animated animate__slideInLeft">
            {" "}
            छठ पर्व किस प्रकार मनाते हैं ?
          </h4>
          <hr />
          <p>
            यह पर्व चार दिनों का है। भैयादूज के तीसरे दिन से यह आरम्भ होता है।
            पहले दिन सेन्धा नमक, घी से बना हुआ अरवा चावल और कद्दू की सब्जी
            प्रसाद के रूप में ली जाती है। अगले दिन से उपवास आरम्भ होता है। व्रति
            दिनभर अन्न-जल त्याग कर शाम करीब 7 बजे से खीर बनाकर, पूजा करने के
            उपरान्त प्रसाद ग्रहण करते हैं, जिसे <span>खरना</span> कहते हैं।
            तीसरे दिन डूबते हुए सूर्य को अर्घ्य यानी दूध अर्पण करते हैं। अंतिम
            दिन उगते हुए सूर्य को अर्घ्य चढ़ाते हैं।
            <span>पूजा में पवित्रता का विशेष ध्यान रखा जाता है,</span> लहसून,
            प्याज वर्जित होता है। जिन घरों में यह पूजा होती है, वहाँ भक्तिगीत
            गाये जाते हैं।अंत में लोगो को पूजा का प्रसाद दिया जाता हैं।
          </p>
        </div>
        <div className="page">
          <h4 className="animate__animated animate__slideInLeft">
            {" "}
            सूर्य पूजा का संदर्भ
          </h4>
          <hr />
          <p>
            छठ पर्व मूलतः सूर्य की आराधना का पर्व है, जिसे हिन्दू धर्म में विशेष
            स्थान प्राप्त है। हिन्दू धर्म के देवताओं में सूर्य ऐसे देवता हैं
            जिन्हें मूर्त रूप में देखा जा सकता है।
            <br />
            <span>
              {" "}
              सूर्य की शक्तियों का मुख्य श्रोत उनकी पत्नी ऊषा और प्रत्यूषा हैं।
            </span>{" "}
            छठ में सूर्य के साथ-साथ दोनों शक्तियों की संयुक्त आराधना होती है।
            <span>
              {" "}
              प्रात:काल में सूर्य की पहली किरण (ऊषा) और सायंकाल में सूर्य की
              अंतिम किरण (प्रत्यूषा) को अर्घ्य देकर दोनों का नमन किया जाता है।
            </span>
          </p>
        </div>
        <div className="page">
          <h4 className="animate__animated animate__slideInLeft">
            {" "}
            सूर्योपासना की परम्परा{" "}
          </h4>
          <hr />
          <p>
            भारत में सूर्योपासना ऋगवेद काल से होती आ रही है। सूर्य और इसकी
            उपासना की चर्चा विष्णु पुराण, भगवत पुराण, ब्रह्मा वैवर्त पुराण आदि
            में विस्तार से की गयी है। मध्य काल तक छठ सूर्योपासना के व्यवस्थित
            पर्व के रूप में प्रतिष्ठित हो गया, जो अभी तक चला आ रहा है।
          </p>
        </div>
        <div className="page">
          <h4 className="animate__animated animate__slideInLeft">
            {" "}
            सामाजिक/सांस्कृतिक महत्त्व{" "}
          </h4>
          <hr />
          <p>
            <span>
              छठ पूजा का सबसे महत्त्वपूर्ण पक्ष इसकी सादगी पवित्रता और लोकपक्ष
              है।
            </span>{" "}
            भक्ति और आध्यात्म से परिपूर्ण इस पर्व में{" "}
            <span>
              बाँस निर्मित सूप, टोकरी, मिट्टी के बर्त्तनों, गन्ने का रस, गुड़,
              चावल और गेहूँ
            </span>{" "}
            से निर्मित प्रसाद और सुमधुर लोकगीतों से युक्त होकर लोक जीवन की भरपूर
            मिठास का प्रसार करता है।
            <br />
            <br />
            शास्त्रों से अलग यह जन सामान्य द्वारा अपने रीति-रिवाजों के रंगों में
            गढ़ी गयी उपासना पद्धति है। इसके केंद्र में वेद, पुराण जैसे
            धर्मग्रन्थ न होकर किसान और ग्रामीण जीवन है।{" "}
            <span>इस व्रत के लिए न विशेष धन की आवश्यकता है</span> न पुरोहित या
            गुरु के अभ्यर्थना की। जरूरत पड़ती है तो पास-पड़ोस के सहयोग की जो
            अपनी सेवा के लिए सहर्ष और कृतज्ञतापूर्वक प्रस्तुत रहता है।
            <span>
              {" "}
              इस उत्सव के लिए जनता स्वयं अपने सामूहिक अभियान संगठित करती है।
              नगरों की सफाई, व्रतियों के गुजरने वाले रास्तों का प्रबन्धन, तालाब
              या नदी किनारे अर्घ्य दान की उपयुक्त व्यवस्था के लिए समाज सरकार के
              सहायता की राह नहीं देखता।
            </span>{" "}
            इस उत्सव में खरना के उत्सव से लेकर अर्ध्यदान तक समाज की अनिवार्य
            उपस्थिति बनी रहती है। यह सामान्य और गरीब जनता के अपने दैनिक जीवन की
            मुश्किलों को भुलाकर सेवा-भाव और भक्ति-भाव से किये गये सामूहिक कर्म
            का विराट और भव्य प्रदर्शन है।
          </p>
        </div>
        <div className="page">
          <h4 className="animate__animated animate__slideInLeft"> तिथि </h4>
          <hr />
          <p>
            <span>
              दीपावली के छठे दिन से शुरू होने वाला छठ का पर्व चार दिनों तक चलता
              है।
            </span>{" "}
            इन चारों दिन श्रद्धालु भगवान सूर्य की आराधना करके वर्षभर सुखी,
            स्वस्थ और निरोगी होने की कामना करते हैं। चार दिनों के इस पर्व के
            पहले दिन घर की साफ-सफाई की जाती है।
          </p>
        </div>
        <div className="page">
          <h4 className="animate__animated animate__slideInLeft"> धन्यवाद </h4>
          <hr />
          <p>
            पेज से जुड़ने के लिए बहुत बहुत धन्यवाद।
            <span>
              {" "}
              श्री श्री छठ पूजा समिति। एकता नवयुवक संघ शिव सरोवर संझौली आप सभी
              श्रद्धालुओं का हार्दिक अभिनंदन बंदन करता था, करता हैं और करता ही
              रहेगा।
            </span>
            आपका साथ/सहयोग पाकर समिति बहुत प्रसन्न हुईं।। छठी माता आपकी हर
            मनोवांचिंत कामनाएं पूर्ण करें।। <br />
            <br />
            जय हों छठी मईया | श्री श्री छठ पूजा समिति। एकता नवयुवक संघ शिव सरोवर
            संझौली , रोहतास (बिहार)
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
